import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, ListGroup, Table, Tab, Tabs } from 'react-bootstrap';
import Header from "../components/header";
import Menu from "../components/menu";
import { useDispatch, useSelector } from "react-redux/";
import { useNavigate } from "react-router-dom";
import SubscriptionPlans from "./subscriptionPlans";
import Enrollments from "./enrollments";
function Configuration() {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [key, setKey] = useState("enrollments");

    return (
        <>
            <Header />
            <>
                <Row className='home_outer'>

                    <Menu />

                    <Col className='right_home'>

                        <Row>
                            <Col lg="12" >
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="mb-3"
                                >
                                    <Tab eventKey="enrollments" title="Enrollments">

                                        <Enrollments />

                                    </Tab>

                                    <Tab eventKey="subscriptions" title="Subscription History">
                                        Subscriptions
                                    </Tab>
                                    <Tab eventKey="payments" title="Payments History">
                                        Payments
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Col>



                </Row>

            </>
        </>
    )

}

export default Configuration;