import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal, Form, Table } from 'react-bootstrap'
import { useSelector } from "react-redux";
import { useModal } from "./ModalContext";
import { toast } from "react-toastify";
import Menu from "../components/menu";
import Header from "../components/header";
function SubscriptionPlans() {

  const userId = useSelector((e) => {
    return e.user.user.userId;
  })
  const { openModal } = useModal();
  const accessToken = useSelector(state => state.user.user.accessToken);

  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const [showModal, setShowModal] = useState(false);
  const [adding, setAdding] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [actionType, setActionType] = useState('addSubscriptionPlan');

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  // const [categoryId, setCategoryId] = useState([]);
  const [price, setPrice] = useState('');
  const [yearlyPrice, setYearlyPrice] = useState('');
  const [stripeProductId, setStripeProductId] = useState('');
  const [status, setStatus] = useState('');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedID, setSelectedID] = useState('');

  const handleDeleteSubscriptionPlan = (planId) => {
    setSelectedID(planId);
    setShowDeleteModal(true);
  };

  const getSubscriptionPlans = () => {
    setIsLoading(true);
    try {
      fetch(`${process.env.REACT_APP_API_URL}/auth/admin/subscriptionPlans`, {
        method: 'GET',
        headers: headers,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message == "token_expired") {
            openModal();
            return;
          }
          if (data.data) {
            setSubscriptionPlans(data.data)
            setIsLoading(false)
          }
          // getCategories();
        });
    } catch (e) {

    }
  }

  // const getCategories = async () => {
  //   setIsLoading(true);
  //   fetch(`${process.env.REACT_APP_API_URL}/categories`, {
  //     method: "GET",
  //     headers: headers,
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setIsLoading(false);
  //       if (data.message == "token_expired") {
  //         return;
  //       }
  //       setCategories(data)
  //     });
  // }

  useEffect(() => {
    getSubscriptionPlans();
  }, [])

  const addSubscriptionPlan = async () => {

    setAdding(true);

    if (!name || !description  || !price || !yearlyPrice) {

      toast.error('All fields are required', {
        position: 'top-center'
      })
      setAdding(false);
      return;

    }

    const postData = { userId, name, description, price, yearlyPrice };

    try {

      const request = await fetch(`${process.env.REACT_APP_API_URL}/subscriptionPlans`, {
        method: 'POST',
        body: JSON.stringify(postData),
        headers,
      })

      const response = await request.json();

      if (response.message == "token_expired") {
        setShowModal(false)
        openModal();
        return;
      }

      if (response.message == "success") {
        clearForm();
        getSubscriptionPlans();
        toast.success('Subscription plan added successfully', {
          position: 'top-center'
        })
        setShowModal(false);
      } else {
        toast.error('An error occurred', {
          position: 'top-center'
        })
      }

      setAdding(false)

    } catch (e) {

      toast.error('An error occurred!', {
        position: 'top-center'
      })

      setAdding(false)

    }

  }

  const updateSubscriptionPlan = async () => {

    setAdding(true);

    if (!id || !userId || !name  || !price || !yearlyPrice || !stripeProductId || !status) {

      toast.error('All fields are required', {
        position: 'top-center'
      })
      setAdding(false);
      return;

    }

    const postData = { id, userId, name, description, price, yearlyPrice, stripeProductId, status };

    try {

      const request = await fetch(`${process.env.REACT_APP_API_URL}/subscriptionPlans`, {
        method: 'PUT',
        body: JSON.stringify(postData),
        headers,
      })

      const response = await request.json();

      if (response.message == "token_expired") {
        setShowModal(false)
        openModal();
        return;
      }

      if (response.message == "success") {
        clearForm();
        getSubscriptionPlans();
        toast.success('Subscription plan updated successfully', {
          position: 'top-center'
        })
        setShowModal(false);
      } else {
        toast.error('An error occurred', {
          position: 'top-center'
        })
      }

      setAdding(false)

    } catch (e) {

      toast.error('An error occurred!', {
        position: 'top-center'
      })

      setAdding(false)

    }

  }

  const deleteSubscriptionPlan = async () => {

    setAdding(true);

    try {
      const request = await fetch(`${process.env.REACT_APP_API_URL}/subscriptionPlans/${selectedID}`, {
        method: "DELETE",
        headers,
      })

      const response = await request.json();

      if (response.message == "success") {
        setSelectedID('');
        getSubscriptionPlans();
        setShowDeleteModal(false);
      }

      setAdding(false);

    } catch (e) {
      setAdding(false);
    }

  }

  const loadUpdateSubscriptionPlan = async (planId) => {

    setIsLoading(true);

    try {
      const request = await fetch(`${process.env.REACT_APP_API_URL}/subscriptionPlan/${planId}`, {
        method: 'GET',
        headers,
      })

      const response = await request.json();

      if (response.message == "token_expired") {
        openModal();
        return;
      }

      if (response.feedback == "success") {
        setActionType('updateSubscriptionPlan');
        setId(response.data[0].id)
        setName(response.data[0].name);
        setDescription(response.data[0].description);
        // setCategoryId(JSON.parse(response.data[0].categoryId));
        setPrice(response.data[0].price);
        setYearlyPrice(response.data[0].yearlyPrice);
        setStripeProductId(response.data[0].stripeProductId);
        setStatus(response.data[0].status);
        setShowModal(true)

      }

      setIsLoading(false);

    } catch (e) {

      setIsLoading(false);

    }

  }

  const clearForm = () => {
    setId('');
    setName('');
    setDescription('');
    // setCategoryId('');
    setPrice('');
    setYearlyPrice('');
    setStatus('');
  }

  // const handleCategorySelect = (e) => {
  //   const selectedValues = Array.from(e.target.options)
  //     .filter((option) => option.selected)
  //     .map((option) => option.value);

  //   setCategoryId(selectedValues);

  // };

  return (
    <>

 <Header />
 <Row className='home_outer'>
  <Menu />
  <Col className='right_home'>
  <Row>
    <Col lg="11">
     <h5>Subscription Plans</h5>
   <div className="shadow-lg" style={{ padding: 10, border: "0px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>
      
       

        <Row>
          <Col>
            <Button variant="primary" onClick={() => {
              setActionType('addSubscriptionPlan')
              setShowModal(true)
            }}>
              Add Subscription Plan
            </Button>
          </Col>
        </Row>
        <br />
        {isLoading &&
          <div>
            <span className="loader"></span>
          </div>
        }
        <Row>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                    <th>Duration</th>
                  
                  <th>Status</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {subscriptionPlans.map((plan) => (
                  <tr key={plan.package_id}>
                    <td>{plan.package_name}</td>
                    <td>₦{plan.price}</td>
                      <td>{plan.duration} Days</td>
                    <td>{plan.status == "0" ? <>Inactive</> : <>Active</>}</td>
                    <td>
                      <Button variant="info" onClick={() => { loadUpdateSubscriptionPlan(plan.id); }}>
                        View Users
                      </Button>
                    </td>
                    <td>
                       <Button variant="info" onClick={() => { loadUpdateSubscriptionPlan(plan.id); }}>
                        View
                      </Button>
                      <Button variant="danger" onClick={() => { handleDeleteSubscriptionPlan(plan.id); }}>
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>

      </div> 
    </Col>
  </Row>
  </Col>
     
</Row>
      {/* CREATE SUBSCRIPTION PLAN MODAL */}
      <Modal show={showModal} onHide={() => {
        clearForm();
        setShowModal(false)
      }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {actionType == "addSubscriptionPlan" ?
              <>Add a Subscription Plan</>
              :
              <>Update Subscription plan</>
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <br />
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
           
           
            <br />
            <Form.Group>
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label>Yearly Price</Form.Label>
              <Form.Control
                type="number"
                value={yearlyPrice}
                onChange={(e) => setYearlyPrice(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Stripe Product ID</Form.Label>
              <Form.Control
                type="text"
                value={stripeProductId}
                onChange={(e) => setStripeProductId(e.target.value)}
              />
            </Form.Group>
            {actionType == "updateSubscriptionPlan" &&
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="">
                    Select status
                  </option>

                  <option value="1">
                    Active
                  </option>

                  <option value="0">
                    Inactive
                  </option>

                </Form.Control>
              </Form.Group>
            }
          </Form>
          {adding &&
            <div>
              <span className="loader"></span>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={() => {
            if (actionType == "addSubscriptionPlan") {
              addSubscriptionPlan();
            } else {
              updateSubscriptionPlan();
            }
          }}>
            {actionType == "addSubscriptionPlan" ?
              <>{adding ? <>Add...</> : <>Add</>} </>
              :
              <>{adding ? <>Updating...</> : <>Update</>} </>
            }
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>

      {/* DELETE MODAL */}
      <Modal show={showDeleteModal} onHide={() => {
        setSelectedID("");
        setShowDeleteModal(false)
      }}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Subscription Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This is a permanent and irreversible action!
          </p>
          <p>
            Click yes if you are sure!!!
          </p>

          {adding &&
            <center>
              <div>
                <span className="loader"></span>
              </div>
            </center>
          }

        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={deleteSubscriptionPlan}>
            Yes
          </Button>
          <Button variant="danger" onClick={() => {
            setSelectedID("");
            setShowDeleteModal(false);
          }}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SubscriptionPlans;