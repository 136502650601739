
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { config } from '../config';
import Menu from '../components/menu';
import { Placeholder } from 'react-bootstrap';
import Header from '../components/header';
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { useModal } from './ModalContext';
  
function CastechisamVideos() {

    const userId = useSelector((e) => {
        return e.user.user.userId;
    })
    const { openModal } = useModal();
    const accessToken = useSelector(state => state.user.user.accessToken);

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
    };

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [castechisamVideo, setCastechisamVideos] = useState([]);
const [showModal, setShowModal] = useState(false);
const [loading, setLoading] = useState(false);
const {ID,catechismTitle } = useParams();


       const [title, setTitle] = useState('');
      const [sub_title,setSubTitle] = useState('');
       const [video_url,setVideo_url] = useState('');
      
      const [description,setDescription] = useState('');

    // Load courses and categories from the API
    useEffect(() => {
         getMessages();
    }, []);
  function clearForm() {
       
    }
    const getMessages = () => {
        setIsLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/auth/admin/loadAllCastechisamVideos?ID=${ID}`, {
            method: 'GET',
            headers: headers,
        })
            .then((res) => res.json())
            .then((data) => {

                    console.log(data);
                if (data.message == "token_expired") {
                    openModal();
                    return;
                }

                if (data.success == true) {
                    // console.log("setCastechisam_Videos=>", data.body)
                    setCastechisamVideos(data.body)

                    //console.log("setCastechisam_Videos=>", castechisamVideo)
                    setIsLoading(false)

                } else {
                    setIsLoading(false)
                }

            });
    }


const createCastechisamVideo = () => {

    if (!title) {
      toast.error("title is required", {
        position: "top-center"
      })
      return;
    }
      setIsLoading(true);


       const userData = {
      title,
      sub_title,
     ID,
      description,
      video_url,
      requestKey: process.env.REACT_APP_SERVER_KEY
    };

    //setAdding(true);

    fetch(`${process.env.REACT_APP_API_URL}/auth/admin/addCatechismVideo `, {
      method: 'POST',
      headers,
      body: JSON.stringify(userData),
    })
      .then((response) => {
        setIsLoading(false);
        return response.json();  // Parse the response body as JSON
      })
      .then((data) => {

        if (data.message == "token_expired") {
          openModal();
          return;
        }

       


        if (data.feedback != true) {
          toast.error(data.message, {
            position: "top-center"
          })
          return;

        }

         if (data.feedback == true) {
          toast.success(data.message, {
            position: "top-center"
          })
        

        }
        setIsLoading(false);
       setShowModal(false);
     

      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
    
}
    return (
        <>
            <Header />

            <Row className='home_outer'>

                <Menu />

                <Col className='right_home'>

                    {isLoading &&
                        <div>
                            <span className="loader"></span>
                        </div>
                    }

                    <Row>
                        <Col>
                            <h1>Castechisam Videos</h1>
                            <p>{catechismTitle}</p>
                        </Col>

                         <Col>
                           
                        <Button variant="primary" onClick={() => setShowModal(true)}>
                                    Add New Video
                                </Button>
                        </Col>
                    </Row>

                    <div className="shadow-lg" style={{ padding: 10, border: "0px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

                        <Row>
                            <Col lg="12">
                                <>
                                    {/* Course Table */}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>s/n</th>
                                                <th>Title</th>
                                                 <th>Sub Title</th>
                                                 <th>video_url</th>
                                                <th>Edit</th>
                                              
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {castechisamVideo.map((message, index) => (
                                                
                                                <tr key={index}>
                                                    <td>
                                                        {index+1}
                                                    </td>
                                                    <td>
                                                        {message.title}
                                                    </td>
                                                    <td>
                                                        {message.sub_title}
                                                    </td>
                                                     <td>
                                                      {message.video_url}
                                                    </td>
                                                    
                                                     <td>
                                                       <Button variant="info" onClick={() => navigate(`/Viewlag/${message.user_id}/${message.username}`)}>
                              View / Edit
                            </Button>
                             <Button variant="danger" onClick={() => navigate(`/Viewlag/${message.username    }`)} style={{marginLeft: '20px'}}>
                              Remove
                            </Button>
                                                    </td>
                                                    
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                </>

                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>


             {/* Modal for Creating New Quiz */}
                        <Modal show={showModal} onHide={() => {
                            clearForm();
                            setShowModal(false);
                        }}>
                            <Modal.Header closeButton>
                                <Modal.Title>Upload New Castechisam Video<br/>
                    <p>{catechismTitle}</p>

                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={title}
                                           onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </Form.Group>


                                    <Form.Group>
                                        <Form.Label>Sub title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={sub_title}
                                            onChange={(e) => setSubTitle(e.target.value)}
                                        />
                                    </Form.Group>

                                     <Form.Group>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Video url</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={video_url}
                                            onChange={(e) => setVideo_url(e.target.value)}
                                            
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Thumbnail url</Form.Label>
                                        <Form.Control
                                            type="text"
                                            
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Tags separate with commas (,)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            
                                        />
                                    </Form.Group>
                                   
                                   <Form.Group>


                        <Form.Check // prettier-ignore
                                    type="checkbox"
                                    id=""
                                    label="is premium"
                                         />
                                    </Form.Group>
                                    <br />
                                   
                                </Form>

                                {loading &&
                                    <div>
                                        <span className="loader"></span>
                                    </div>
                                }

                            </Modal.Body>
                            <Modal.Footer>

                                <Button variant="primary" onClick={()=>{createCastechisamVideo()}} >
                                    Add 
                                </Button>

                                <Button variant="secondary" onClick={() => {
                                    setShowModal(false);
                                    clearForm();
                                }}>
                                    Close
                                </Button>

                            </Modal.Footer>
                        </Modal>

                    


        </>
    );
}

export default CastechisamVideos;
