
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { config } from '../config';
import Menu from '../components/menu';
import { Placeholder } from 'react-bootstrap';
import Header from '../components/header';
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { useModal } from './ModalContext';
  
function CatechismTexts() {

    const userId = useSelector((e) => {
        return e.user.user.userId;
    })
    const { openModal } = useModal();
    const accessToken = useSelector(state => state.user.user.accessToken);

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
    };

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [Castechisam_Videos, setCastechisam_Videos] = useState([]);
const [showModal, setShowModal] = useState(false);
const [loading, setLoading] = useState(false);
const {ID,catechismTitle } = useParams();


       const [question, setQuestion] = useState('');
      const [answer,setAnswer] = useState('');
      const[optionA,setOptionA] = useState('');
      const[optionB,setOptionB] = useState('');
      const[optionC,setOptionC] = useState('');
      const[corretoption,setCurretOption] = useState('');
      

      
      const [description,setDescription] = useState('');

    // Load courses and categories from the API
    useEffect(() => {
         getMessages();
    }, []);
  function clearForm() {
       
    }
    const getMessages = () => {
        setIsLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/auth/admin/loadAllCatechismTexts?ID=${ID}`, {
            method: 'GET',
            headers: headers,
        })
            .then((res) => res.json())
            .then((data) => {

                    console.log(data);
                if (data.message == "token_expired") {
                    openModal();
                    return;
                }

                if (data.feedback == true) {
                     console.log("setCastechisam_Videos=>", data.data)
                    setCastechisam_Videos(data.data)

                    console.log("setCastechisam_Videos=>", Castechisam_Videos)
                    setIsLoading(false)

                } else {
                    setIsLoading(false)
                }

            });
    }
const createCastechisamQuestion = () => {

    if (!question) {
      toast.error("question is required", {
        position: "top-center"
      })
      return;
    }
      setIsLoading(true);


       const userData = {
      question,
      answer,
     ID,
      description,
      requestKey: process.env.REACT_APP_SERVER_KEY
    };

    //setAdding(true);

    fetch(`${process.env.REACT_APP_API_URL}/auth/admin/createCastechisamQuestion `, {
      method: 'POST',
      headers,
      body: JSON.stringify(userData),
    })
      .then((response) => {
        setIsLoading(false);
        return response.json();  // Parse the response body as JSON
      })
      .then((data) => {

        if (data.message == "token_expired") {
          openModal();
          return;
        }

       


        if (data.feedback != true) {
          toast.error(data.message, {
            position: "top-center"
          })
          return;

        }

         if (data.feedback == true) {
          toast.success(data.message, {
            position: "top-center"
          })
        getMessages();

        }
        setIsLoading(false);
       setShowModal(false);
     

      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
    
}
    return (
        <>
            <Header />

            <Row className='home_outer'>

                <Menu />

                <Col className='right_home'>

                    {isLoading &&
                        <div>
                            <span className="loader"></span>
                        </div>
                    }

                    <Row>
                        <Col>
                            <h1>Castechisam Texts</h1>
                            <p>{catechismTitle}</p>
                        </Col>

                         <Col>
                           
                        <Button variant="primary" onClick={() => setShowModal(true)}>
                                    Add New Texts
                                </Button>
                        </Col>
                    </Row>

                    <div className="shadow-lg" style={{ padding: 10, border: "0px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

                        <Row>
                            <Col lg="12">
                                <>
                                    {/* Course Table */}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>s/n</th>
                                                <th>questions</th>
                                                 <th>Answer</th>
                                                 <th>Description</th>
                                                <th>Edit</th>
                                              
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Castechisam_Videos.map((message, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {index+1}
                                                    </td>
                                                    <td>
                                                        {message.question}
                                                    </td>
                                                    <td>
                                                        {message.answer}
                                                    </td>
                                                     <td>
                                                      {message.description}
                                                    </td>
                                                    
                                                     <td>
                                                       <Button variant="info" onClick={() => navigate(`/Viewlag/${message.catechism_text_id}/`)}>
                             Edit
                            </Button>
                             <Button variant="danger" onClick={() => navigate(`/Viewlag/${message.catechism_text_id    }`)} style={{marginLeft: '20px'}}>
                              Remove
                            </Button>
                                                    </td>
                                                    
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                </>

                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>


             {/* Modal for Creating New Quiz */}
                        <Modal show={showModal} onHide={() => {
                            clearForm();
                            setShowModal(false);
                        }}>
                            <Modal.Header closeButton>
                                <Modal.Title>Upload New Castechisam Questions<br/>
                    <p>{catechismTitle}</p>

                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Question</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={question}
                                           onChange={(e) => setQuestion(e.target.value)}
                                        />
                                    </Form.Group>


                                    <Form.Group>
                                        <Form.Label>Answer</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={answer}
                                            onChange={(e) => setAnswer(e.target.value)}
                                        />
                                    </Form.Group>

                                     <Form.Group>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            
                                        />



                                    </Form.Group>


                                     <Form.Group>
                                        <Form.Label>Option A</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={optionA}
                                            onChange={(e) => setOptionA(e.target.value)}
                                            
                                        />


                                        
                                    </Form.Group>

 <Form.Group>
                                        <Form.Label>Option B</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={optionB}
                                            onChange={(e) => setOptionB(e.target.value)}
                                            
                                        />


                                        
                                    </Form.Group> <Form.Group>
                                        <Form.Label>Option C</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={optionC}
                                            onChange={(e) => setOptionC(e.target.value)}
                                            
                                        />


                                        
                                    </Form.Group> <Form.Group>
                                        <Form.Label>Corret Option</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={corretoption}
                                            onChange={(e) => setCurretOption(e.target.value)}
                                            
                                        />


                                        
                                    </Form.Group>
                                   
                                   
                                   

                                {loading &&
                                    <div>
                                        <span className="loader"></span>
                                    </div>
                                }
                            </Form>
                            </Modal.Body>
                            <Modal.Footer>

                                <Button variant="primary" onClick={()=>{createCastechisamQuestion()}} >
                                   Save
                                </Button>

                                <Button variant="secondary" onClick={() => {
                                    setShowModal(false);
                                    clearForm();
                                }}>
                                    Close
                                </Button>

                            </Modal.Footer>
                        </Modal>

                    


        </>
    );
}

export default CatechismTexts;
