import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, ListGroup, Table,Modal,Form } from 'react-bootstrap';

import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { useModal } from "./ModalContext";
import Header from "../components/header";
import Menu from "../components/menu";
function Trailers() {
      const { openModal } = useModal();
       const navigate = useNavigate();
 const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState("");
    const [video_url, setVideourl] = useState("");
    const [subtitle, setSubTitle] = useState("");
     const [adding, setAdding] = useState(false);
    const [episods, setEpisodes] = useState([]);
    const [thumbnail_url, setThumbnail_url] = useState("");
    const [description, setDescription] = useState("");
    const [traillers, setTraillersVideos] = useState([]);
    const [episode_id, setepisode_id] = useState("");

    
const accessToken = useSelector(state => state.user.user.accessToken);

    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
    };


      const getTraillers = () => {
        setIsLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/auth/admin/getTrailersVideo`, {
            method: 'GET',
            headers: headers,
        })
            .then((res) => res.json())
            .then((data) => {

                    console.log(data);
                if (data.message == "token_expired") {
                    openModal();
                    return;
                }

                if (data.message == 'Success') {
                  
                    setTraillersVideos(data.data);
                    console.log("setTraillersVideos=>", data.data)

                 
                    setIsLoading(false)

                } else {
                    setIsLoading(false)
                }

            });
    }
const saveTraillers =() =>{
  if (!title) {
      toast.error("title is required", {
        position: "top-center"
      })
      return;
    }
    if (!episode_id) {
      toast.error("episode is required", {
        position: "top-center"
      })
      return;
    }

    
      setIsLoading(true);


       const userData = {
      title,
      episode_id,
      subtitle,
     thumbnail_url,
      description,
      video_url,
      requestKey: process.env.REACT_APP_SERVER_KEY
    };

    //setAdding(true);

    fetch(`${process.env.REACT_APP_API_URL}/auth/admin/addTrailersVideo`, {
      method: 'POST',
      headers,
      body: JSON.stringify(userData),
    })
      .then((response) => {
        setIsLoading(false);
        return response.json();  // Parse the response body as JSON
      })
      .then((data) => {

        if (data.message == "token_expired") {
          openModal();
          return;
        }

       


        if (data.feedback != true) {
          toast.error(data.message, {
            position: "top-center"
          })
          return;

        }

         if (data.feedback == true) {
          toast.success(data.message, {
            position: "top-center"
          })
        

        }
        setIsLoading(false);
       setShowModal(false);
     

      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });

}

         const getEpisodes = () => {
    
    let queryUrl = `${process.env.REACT_APP_API_URL}/auth/admin/getEpisodes`;
    fetch(queryUrl, {
      method: 'GET',
      // headers,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message == "token_expired") {
        //   openModal();
          return;
        }
     setEpisodes(data.data)
        // setIsLoading(false)
      });
  }

  useEffect(() => {
    getTraillers();
         getEpisodes();
    }, []);

    return (
        <>
         <Header />
       
               <Row className='home_outer'>

                    <Menu />

                    <Col className='right_home'>
 <Col>
              {isLoading &&
                        <div>
                            <span className="loader"></span>
                        </div>
                    } 
              </Col>
                        <Row>
                            <Col lg="11">

                                <h1>Sabi-Castechisam Trailers</h1>

                                <div className="shadow-lg" style={{ padding: 10, border: "0px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>
 <Button variant="primary" onClick={() => setShowModal(true)}>
                  Add  Trailers
                </Button>
                                    <Row>
                                       <div className="shadow-lg" style={{ padding: 10, border: "0px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

                        <Row>
                            <Col lg="12">
                                <>
                                    {/* Course Table */}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>s/n</th>
                                                <th>Title</th>
                                                 <th>Sub Title</th>
                                                 <th>video_url</th>
                                                <th>Edit</th>
                                              
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {traillers.map((message, index) => (
                                                
                                                <tr key={index}>
                                                    <td>
                                                        {index+1}
                                                    </td>
                                                    <td>
                                                        {message.title}
                                                    </td>
                                                    <td>
                                                        {message.sub_title}
                                                    </td>
                                                     <td>
                                                      {message.video_url}
                                                    </td>
                                                    
                                                     <td>
                                                       <Button variant="info" onClick={() => navigate(`/Viewlag/${message.user_id}/${message.username}`)}>
                              View / Edit
                            </Button>
                             <Button variant="danger" onClick={() => navigate(`/Viewlag/${message.username    }`)} style={{marginLeft: '20px'}}>
                              Remove
                            </Button>
                                                    </td>
                                                    
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                </>

                            </Col>
                        </Row>

                    </div>

                                    </Row>

                                </div>
                            </Col>
                        </Row>

                    </Col>



                </Row>
 <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        Add  Trailers
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group>
                                        <Form.Label> Episode </Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={episode_id}
                                             onChange={(e) => setepisode_id(e.target.value)}
                                        >
                                          
                                            <option value="">
                                                Select episods
                                            </option>
                                            {episods.map((episod) => (
                                                <option key={episod.episode_id} value={episod.episode_id}>
                                                    {episod.title}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                        <Form.Group>
                          <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                                        />
                        </Form.Group>
                        <Form.Group>
                                        <Form.Label>Sub title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={subtitle}
                            onChange={(e) => setSubTitle(e.target.value)}
                                            
                                        />
                                    </Form.Group>

                                     <Form.Group>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Video url</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={video_url}
                                            onChange={(e) => setVideourl(e.target.value)}
                                            
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Thumbnail url</Form.Label>
                                        <Form.Control
                                            type="text"
                                             value={thumbnail_url}
                                            onChange={(e) => setThumbnail_url(e.target.value)}
                                            
                                        />
                                    </Form.Group>  
                      </Form>
                      {adding &&
                        <div>
                          <span className="loader"></span>
                        </div>
                      }
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={() =>saveTraillers()}>
                        Add
                      </Button>
                    </Modal.Footer>
                  </Modal>
       
        </>
    )
}

export default Trailers