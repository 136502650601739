import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import Menu from '../components/menu';

import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { useModal } from "./ModalContext";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify'
function EpisodeCatechisms() {
    const { openModal } = useModal();
    const navigate = useNavigate();

      const [isLoading, setIsLoading] = useState(true);
       const [showModal, setShowModal] = useState(false);
       const [adding, setAdding] = useState(false);
       const [allEpisodes, setEpisodes] = useState([]);


       const [title, setEpisodesTitle] = useState('');
      const [sub_title,setEpisodesSubTitle] = useState('');
    //   const [thumbnail_url, setEpisodesThumbnailUrl] = useState('');
    //   const [language,setEpisodesLanguage] = useState('');
      const [description,setEpisodesDescription] = useState('');

const {ID,catechismTitle } = useParams();


    const userId = useSelector((e) => {
    return e.user.user.userId;
  })
const accessToken = useSelector(state => state.user.user.accessToken);
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };
 useEffect(() => {

    getEpisodes();

  }, [])
       const getEpisodes = () => {
    setIsLoading(true);
    let queryUrl = `${process.env.REACT_APP_API_URL}/auth/admin/getEpisodeCatechisms?ID=${ID}`;
    fetch(queryUrl, {
      method: 'GET',
      headers,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message == "token_expired") {
        //   openModal();
          return;
        }
     setEpisodes(data.data)
        setIsLoading(false)
      });
  }

  const addNewCatechismsEpisodes = () => {
   if (!title) {
      toast.error("title is required", {
        position: "top-center"
      })
      return;
    }
      setAdding(true);


       const userData = {
      title,
      sub_title,
     ID,
      description,
      requestKey: process.env.REACT_APP_SERVER_KEY
    };

    //setAdding(true);

    fetch(`${process.env.REACT_APP_API_URL}/auth/admin/addEpisodeCatechisms `, {
      method: 'POST',
      headers,
      body: JSON.stringify(userData),
    })
      .then((response) => {
        setAdding(false);
        return response.json();  // Parse the response body as JSON
      })
      .then((data) => {

        if (data.message == "token_expired") {
          openModal();
          return;
        }

       


        if (data.feedback != true) {
          toast.error(data.message, {
            position: "top-center"
          })
          return;

        }

         if (data.feedback == true) {
          toast.success(data.message, {
            position: "top-center"
          })
        

        }
        setAdding(false);
       setShowModal(false);
       getEpisodes();

      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }
  return (
    <>
        <Header />
         <Row className='home_outer'>

        <Menu />

        <Col className='right_home'>

          {isLoading &&
            <div>
              <span className="loader"></span>
            </div>
          }

          <Row>
            <Col>
              <h1>{catechismTitle}</h1>
            </Col>
          </Row>

          <div className="shadow-lg" style={{ padding: 10, border: "0px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

            <Row>
              <Col>
                <Button variant="primary" onClick={() => setShowModal(true)}>
                  Add New 
                </Button>
              </Col>
            </Row>

            <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Col>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <>
                  {/* Course Table */}
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>title</th>
                        <th>Videos</th>
                        <th>Texts</th>
                        {/* <th>Songs</th> */}
                        
                        <th></th>
                      </tr>
                    </thead>
                 <tbody>
                      {allEpisodes.map((episode) => (
                        <tr key={episode.catechism_id}>
                             
                          <td><b>{episode.title}</b><br/>
                          
                          ---<small>{episode.sub_title}</small>
                           </td>
        <td> <Button variant="primary" onClick={() => navigate(`/castechisamVideos/${episode.catechism_id}/${episode.title}`)}>
                              View Catechism Videos
                            </Button></td>
                          <td>

                             <Button variant="info" onClick={() => navigate(`/catechismTexts/${episode.catechism_id}/${episode.title}`)}>
                              View Catechism Texts
                            </Button>
                          </td>
                          {/* <td>
                            <Button variant="secondary" onClick={() => navigate(`/editMember/${episode.episode_id}`)}>
                              View Songs
                            </Button>
                          </td> */}
                          <td>
                            <Button variant="danger" onClick={() => navigate(`/editMember/${episode.episode_id}`)}>
                              View
                            </Button>
                          </td>
                         
                         
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Add Catechisms Episodes On <br/>
                        {catechismTitle}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group>
                          <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={title}
                            onChange={(e) => setEpisodesTitle(e.target.value)}
                                        />
                        </Form.Group>
                        <Form.Group>
                                        <Form.Label>Sub title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={sub_title}
                            onChange={(e) => setEpisodesSubTitle(e.target.value)}
                                            
                                        />
                                    </Form.Group>

                                     <Form.Group>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={description}
                                            onChange={(e) => setEpisodesDescription(e.target.value)}
                                            
                                        />
                                    </Form.Group>

                                    {/* <Form.Group>
                                        <Form.Label>Language</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={language}
                                            onChange={(e)=>setEpisodesLanguage(e.target.value)}
                                            
                                        />
                                    </Form.Group> */}

                                    {/* <Form.Group>
                                        <Form.Label>Thumbnail url</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={thumbnail_url}
                                            onChange={(e)=>setEpisodesThumbnailUrl(e.target.value)}
                                            
                                        />
                                    </Form.Group> */}
                      </Form>
                      {adding &&
                        <div>
                          <span className="loader"></span>
                        </div>
                      }
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={()=>addNewCatechismsEpisodes()}>
                        Add
                      </Button>
                    </Modal.Footer>
                  </Modal>

                </>

              </Col>
            </Row>

          </div>

        </Col>

      </Row>
    </>
  )
}

export default EpisodeCatechisms