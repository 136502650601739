import React, { useState } from 'react'
import { Row, Col, Card, Button, ListGroup, Table } from 'react-bootstrap';


import Header from "../components/header";
import Menu from "../components/menu";
function Prayer() {

    

    return (
        <>
         <Header />
       
               <Row className='home_outer'>

                    <Menu />

                    <Col className='right_home'>

                        <Row>
                            <Col lg="11">

                                <h1>Sabi-Castechisam prayers</h1>

                                <div className="shadow-lg" style={{ padding: 10, border: "0px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

                                    <Row>
                                       

                                    </Row>

                                </div>
                            </Col>
                        </Row>

                    </Col>



                </Row>

       
        </>
    )
}

export default Prayer