// App.js
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useModal } from './ModalContext';

function Enrollments() {
  const userId = useSelector((state) => state.user.user.userId);
  const accessToken = useSelector((state) => state.user.user.accessToken);
  const { openModal } = useModal();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [enrollments, setEnrollments] = useState([]);
  const [enrollmentuser, setEnrollmentUser] = useState([]);
  const [user_id, setUserId] = useState('');
  const [packageid, setPackageId] = useState('');
  const [duration, setDuration] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterName, setFilterName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [adding, setAdding] = useState(false);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const apiFetch = (endpoint, options) =>
    fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, options)
      .then((res) => res.json())
      .catch((error) => {
        console.error('API error:', error);
        throw error;
      });

  useEffect(() => {
    getEnrollments();
  }, []);

  const getEnrollments = () => {
    setIsLoading(true);
    apiFetch('/auth/admin/getPayment', {
      method: 'GET',
      headers,
    })
      .then((data) => {
        if (data.message === 'token_expired') {
          openModal();
          return;
        }

        if (data.message === 'Success') {
          setEnrollments(data.data);
        } else {
          toast.error(data.message || 'Failed to fetch enrollments.');
        }
      })
      .catch((error) => {
        toast.error('Error fetching enrollments. Please try again.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const viewEnrollment = async (id) => {
    setShowModal(true);
    setIsLoading(true);

    try {
      const response = await apiFetch(`/auth/admin/viewEnrollment/${id}`, {
        method: 'GET',
        headers,
      });

      if (response.message === 'token_expired') {
        openModal();
        return;
      }

      if (response.data) {
        setEnrollmentUser(response.data);
        if (response.data.length > 0) {
          setUserId(response.data[0].user_id);
          setPackageId(response.data[0].package_id);
          setDuration(response.data[0].duration+' days');
        }
      } else {
        toast.error('Failed to load enrollment details.');
      }
    } catch (error) {
      toast.error('Error loading enrollment details. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const actviaEnrollment = () => {
    if (!user_id || !packageid) {
      toast.error('User ID or Package ID is missing.');
      return;
    }

    setAdding(true);
    const userData = {
      user_id,
      packageid,
      duration,
      requestKey: process.env.REACT_APP_SERVER_KEY,
    };

    apiFetch('/auth/admin/actviaEnrollment', {
      method: 'POST',
      headers,
      body: JSON.stringify(userData),
    })
      .then((data) => {
        if (data.message === 'token_expired') {
          openModal();
          return;
        }

        if (data.feedback === true) {
          toast.success(data.message);
          setShowModal(false);
          getEnrollments();
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        toast.error('Error activating enrollment. Please try again.');
      })
      .finally(() => {
        setAdding(false);
      });
  };

  const filteredEnrollments = enrollments.filter((enrollment) => {
    return (
      (filterStatus === '' || enrollment.status === filterStatus) &&
      (filterName === '' ||
        `${enrollment.first_name} ${enrollment.last_name}`
          .toLowerCase()
          .includes(filterName.toLowerCase()))
    );
  });

  return (
    <>
      <Row className="home_outer">
        <Col className="right_home">
          {isLoading && (
            <div>
              <span className="loader"></span>
            </div>
          )}

          <Row>
            <Col>
              <h3>Enrollments</h3>
            </Col>
          </Row>

          <div
            className="shadow-lg"
            style={{
              padding: 10,
              border: '0px solid #c7c7c7',
              borderRadius: 5,
              minHeight: 400,
              overflow: 'hidden',
            }}
          >
            <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
              <Col>
                <Form.Group>
                  <Form.Label>Filter By Status</Form.Label>
                  <Form.Control
                    as="select"
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                  >
                    <option value="">Select Status</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group>
                  <Form.Label>Filter By Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Package</th>
                      <th>Amount</th>
                      <th>Bank</th>
                      <th>Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredEnrollments.map((enrollment, index) => (
                      <tr key={index}>
                        <td>
                          {enrollment.first_name} {enrollment.last_name}<br/>
                          <small>{enrollment.user_name}</small><br/>
                           <small>{enrollment.phone}</small>
                            
                        </td>
                        <td>{enrollment.package_name}</td>
                        <td>{enrollment.amount}</td>
                        <td>{enrollment.bank_name}</td>
                        <td>{enrollment.created_at}</td>
                        <td>
                          <Button
                            onClick={() => viewEnrollment(enrollment.user_id)}
                            variant="info"
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        {enrollmentuser.length > 0 && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                {enrollmentuser[0].first_name} {enrollmentuser[0].last_name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <b>Package:</b> {enrollmentuser[0].package_name}
                <b> Paid:</b> {enrollmentuser[0].amount}
                <b> Duration:</b> {duration}
              </p>
              <p>
                <b>Payment Ref:</b> {enrollmentuser[0].payment_ref}
              </p>
              <p>
                <b>Bank:</b> {enrollmentuser[0].bank}{' '}
                {enrollmentuser[0].account_name}{' '}
                {enrollmentuser[0].account_number}
              </p>
              <hr />
              UserID: {user_id}
              {adding && (
                <div>
                  <span className="loader"></span>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={actviaEnrollment}>
                Confirm and Activate Now!
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
}

export default Enrollments;
