import React, { Component, useState, useEffect } from 'react'
import Header from '../components/header';
import Menu from '../components/menu';
import { useModal } from './ModalContext';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

function  Banks (){
  
     const { openModal } = useModal();
     const [isLoading, setIsLoading] = useState(true);
const [adding, setAdding] = useState(false);
 const [showModal, setShowModal] = useState(false);

  const accessToken = useSelector(state => state.user.user.accessToken);

  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };
  const [bankList, setBankList] = useState([]);
  const [bankName, setBankName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  useEffect(() => {

  getBanksList();

  }, [])

  const getBanksList = () => {
    setIsLoading(true);
    try {
      fetch(`${process.env.REACT_APP_API_URL}/auth/admin/getBanksList`, {
        method: 'GET',
        headers: headers,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message == "token_expired") {
            openModal();
            return;
          }
          if (data.data) {
            setBankList(data.data)
            setIsLoading(false)
          }
          // getCategories();
        });
    } catch (e) {

    }
  }
    return (
    <>
         <Header />
         <Row className='home_outer'>
<Menu/>
 <Col className='right_home'>

          {isLoading &&
            <div>
              <span className="loader"></span>
            </div>
          }

          <Row>
            <Col>
              <h1>Bank List</h1>
            </Col>
          </Row>

          <div className="shadow-lg" style={{ padding: 10, border: "0px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

            <Row>
              <Col>
                <Button variant="primary" onClick={() => setShowModal(true)}>
                  Add New Bank
                </Button>
              </Col>
            </Row>

           
            <Row>
              <Col lg="12">
                <>
                  {/* Course Table */}
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Bank Name</th>
                        <th>Account Name</th>
                        <th>Account Number</th>
                        
                        <th></th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      
                        {bankList.map((bank) =>
                        <tr key={bank.bank_id}>
                            <td>{bank.bank}</td>
                            <td>{bank.account_name}</td>
                            <td>{bank.account_number}</td>
                            <td>
                                <Button>Edit</Button>
                            </td>
                            </tr>

                            )}

                    </tbody>
                  </Table>

                  <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Add New Bank</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group>
                          <Form.Label>Bank Name</Form.Label>
                          <Form.Control
                            type="text"
                            // value={memberEmail}
                            // onChange={(e) => setMemberEmail(e.target.value)}
                          />
                        </Form.Group>

                         <Form.Group>
                          <Form.Label>Account Name</Form.Label>
                          <Form.Control
                            type="text"
                            // value={memberEmail}
                            // onChange={(e) => setMemberEmail(e.target.value)}
                          />
                        </Form.Group>
                         <Form.Group>
                          <Form.Label>Account Number</Form.Label>
                          <Form.Control
                            type="text"
                            // value={memberEmail}
                            // onChange={(e) => setMemberEmail(e.target.value)}
                          />
                        </Form.Group>
                      </Form>
                      {adding &&
                        <div>
                          <span className="loader"></span>
                        </div>
                      }
                    </Modal.Body>
                    <Modal.Footer>
                      {/* <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                      </Button> */}

                      <Button variant="primary" 
                      >
                        Add Now!
                      </Button>
                    </Modal.Footer>
                  </Modal>

                </>

              </Col>
            </Row>

          </div>

        </Col>


         </Row>
         
    </>


    );
  }

export default Banks;
