import Header from '../components/header';
import Menu from '../components/menu';
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { useModal } from "./ModalContext";
import { useSelector } from 'react-redux';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify'
const Episodes = () => {
  const { openModal } = useModal();
    const navigate = useNavigate();

      const [isLoading, setIsLoading] = useState(true);
       const [showModal, setShowModal] = useState(false);
       const [adding, setAdding] = useState(false);
       const [allEpisodes, setEpisodes] = useState([]);

      const [Modules, setModules] = useState([]);
      const [mod, setMod] = useState("");

       const [title, setEpisodesTitle] = useState('');
      const [sub_title,setEpisodesSubTitle] = useState('');
      const [thumbnail_url, setEpisodesThumbnailUrl] = useState('');
      const [language,setEpisodesLanguage] = useState('');
      const [description,setEpisodesDescription] = useState('');
 const accessToken = useSelector(state => state.user.user.accessToken);

const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  //   const userId = useSelector((e) => {
  //   return e.user.user.userId;
  // })
// const accessToken = useSelector(state => state.user.user.accessToken);
//   const headers = {
//     'Authorization': `Bearer ${accessToken}`,
//     'Content-Type': 'application/json',
//   };
 useEffect(() => {
  fetch(`${process.env.REACT_APP_API_URL}/auth/admin/getModules?requestKey=${process.env.REACT_APP_SERVER_KEY}`)
            .then((res) => res.json())
            .then((data) => setModules(data.data));


    getEpisodes();

  }, [])
       const getEpisodes = () => {
    setIsLoading(true);
    let queryUrl = `${process.env.REACT_APP_API_URL}/auth/admin/getEpisodes`;
    fetch(queryUrl, {
      method: 'GET',
      // headers,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message == "token_expired") {
        //   openModal();
          return;
        }
     setEpisodes(data.data)
        setIsLoading(false)
      });
  }

  const addNewEpisodes = () => {
   if (!title) {
      toast.error("title is required", {
        position: "top-center"
      })
      return;
    }
      setAdding(true);


       const userData = {
      title,
      sub_title,
      thumbnail_url,
      language,
      description,
      mod,
      requestKey: process.env.REACT_APP_SERVER_KEY
    };
console.log(userData);
    //setAdding(true);

    fetch(`${process.env.REACT_APP_API_URL}/auth/admin/addEpisodes`, {
      method: 'POST',
       headers,
      body: JSON.stringify(userData),
    })
      .then((response) => {
        setAdding(false);
        return response.json();  // Parse the response body as JSON
      })
      .then((data) => {

        if (data.message == "token_expired") {
          openModal();
          return;
        }

       


        if (data.feedback != true) {
          toast.error(data.message, {
            position: "top-center"
          })
          return;

        }

         if (data.feedback == true) {
          toast.success(data.message, {
            position: "top-center"
          })
        

        }
        setAdding(false);
       setShowModal(false);
       getEpisodes();

      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }
    return ( <>
<Header />
  <Row className='home_outer'>

        <Menu />

        <Col className='right_home'>

          {isLoading &&
            <div>
              <span className="loader"></span>
            </div>
          }

          <Row>
            <Col>
              <h1>All Episodes</h1>
            </Col>
          </Row>

          <div className="shadow-lg" style={{ padding: 10, border: "0px solid #c7c7c7", borderRadius: 5, minHeight: 400, overflow: "hidden" }}>

            <Row>
              <Col>
                <Button variant="primary" onClick={() => setShowModal(true)}>
                  Add New Episode
                </Button>
              </Col>
             

              
            </Row>

            <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
              


              <Col>

              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <>
                  {/* Course Table */}
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Episodes</th>
                        <th>language</th>
                        <th>Action</th>
                       
                        
                        
                      </tr>
                    </thead>
                 <tbody>
                      {allEpisodes.map((episode) => (
                        <tr key={episode.episode_id}>
                             
                          <td><b>{episode.title}</b><br/>
                          {/* ---{episode.sub_title}<br/>
                          ---<small>{episode.description}</small> */}
                           </td>
                            <td><b>{episode.language}</b> </td>
        <td> <Button variant="danger" style={{ margin:"2px" }} onClick={() => navigate(`/episode_catechisms/${episode.episode_id}/${episode.title}`)}>
                                Catechisms 
                            </Button>


                            <Button variant="info" onClick={() => navigate(`/episode_drama/${episode.episode_id}/${episode.title}`)}>
                                Dramas 
                            </Button>
                              <Button style={{ margin:"2px" }} variant="primary" onClick={() => navigate(`/CastechisamVideos/${episode.episode_id}/${episode.title}`)}>
                                Songs 
                            </Button>
                            </td>
                         
                         
                         
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Add New Episodes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>

                        <Form.Group>
                                        <Form.Label>Modules {mod}</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={mod}
                                            onChange={(e) => setMod(e.target.value)}
                                        >
                                          
                                            <option value="">
                                                Select Modules
                                            </option>
                                            {Modules.map((module) => (
                                                <option key={module.module_id} value={module.module_id}>
                                                    {module.title}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>

                        <Form.Group>
                          <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={title}
                            onChange={(e) => setEpisodesTitle(e.target.value)}
                                        />
                        </Form.Group>
                        <Form.Group>
                                        <Form.Label>Sub title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={sub_title}
                            onChange={(e) => setEpisodesSubTitle(e.target.value)}
                                            
                                        />
                                    </Form.Group>

                                     <Form.Group>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={description}
                                            onChange={(e) => setEpisodesDescription(e.target.value)}
                                            
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Language</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={language}
                                            onChange={(e)=>setEpisodesLanguage(e.target.value)}
                                            
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Thumbnail url</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={thumbnail_url}
                                            onChange={(e)=>setEpisodesThumbnailUrl(e.target.value)}
                                            
                                        />
                                    </Form.Group>
                      </Form>
                      {adding &&
                        <div>
                          <span className="loader"></span>
                        </div>
                      }
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={()=>addNewEpisodes()}>
                        Add
                      </Button>
                    </Modal.Footer>
                  </Modal>

                </>

              </Col>
            </Row>

          </div>

        </Col>

      </Row>
    
    
    </> );
}
 
export default Episodes;